import React, { useState, useEffect } from "react";
import roboto from "./assets/fonts/Roboto Mono_Regular.json";
import  FullParallax  from "./components/FullParallax";

export default function App() {

  return (
    <div className="app-container">
      <FullParallax></FullParallax>
    </div>
  );
}

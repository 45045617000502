import React from "react";

const WarningIconSvg = () => {
  return (
    <svg
      className="warningIcon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502.44 492.92"
    >
      <defs>
        <style>{`.cls-1-svg2 { fill: #1a1a1a; }`}</style>
      </defs>
      <g id="packaging_icon_set" data-name="packaging icon set">
        <g>
          <g>
            <path
              className="cls-1-svg2"
              d="M191.69,405.22H84.5c-16.33,0-24.42-10.96-27.14-15.67-2.72-4.71-8.17-17.2,0-31.34l61.42-106.38,30.04,17.34-22.42-83.58-83.58,22.38,30.04,17.34L11.44,331.7c-15.25,26.41-15.25,57.95,0,84.36,15.25,26.41,42.56,42.18,73.06,42.18h107.19v-53.02Z"
            />
            <path
              className="cls-1-svg2"
              d="M491.28,334.75l-54.56-94.5-45.92,26.51,54.56,94.5c7.64,13.23,2.54,24.9,0,29.31-2.54,4.41-10.11,14.65-25.38,14.65h-138.46v-34.69l-61.17,61.2,61.17,61.19v-34.68h138.46c29.76,0,56.42-15.39,71.3-41.16,14.88-25.78,14.88-56.55,0-82.33Z"
            />
            <path
              className="cls-1-svg2"
              d="M225.1,67.68c7.64-13.23,20.29-14.65,25.38-14.65s17.74,1.43,25.38,14.65l71.63,124.07-30.04,17.34,83.59,22.38,22.4-83.57-30.04,17.34-71.63-124.07C306.89,15.39,280.24,0,250.48,0s-56.42,15.39-71.3,41.16l-63.08,109.26,45.92,26.51,63.08-109.26Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WarningIconSvg;

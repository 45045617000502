import React, { useRef, useState, useEffect, useCallback } from "react";
import Lottie from "react-lottie";
import animationData from "./fire.json"; // Replace with the path to your Lottie animation JSON file
// import styles from "./Intro.scss";

const FireLottie = () => {
  const lottieRef = useRef();
  //   const [isPlaying, setIsPlaying] = useState(false);

  //   const handleClick = useCallback(() => {
  //     setIsPlaying((prevIsPlaying) => !prevIsPlaying);

  //     if (!isPlaying) {
  //       lottieRef.current.setDirection(1);
  //       lottieRef.current.setSpeed(1);
  //       lottieRef.current.play();
  //     } else {
  //       lottieRef.current.setDirection(-1);
  //       lottieRef.current.setSpeed(-1);
  //       lottieRef.current.playSegments([lottieRef.current.currentFrame, 0], true);
  //     }
  //   }, [isPlaying]);
  //   const onAnimationComplete = () => {
  //     // Handle any logic you need when the animation completes
  //   };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //   useEffect(() => {
  //     document.addEventListener('click', handleClick);

  //     return () => {
  //       document.removeEventListener('click', handleClick);
  //     };
  //   }, [handleClick]);

  return (
    <Lottie ref={lottieRef} options={defaultOptions} height={25} width={20} />
  );
};

export default FireLottie;

import React, { useState, useEffect, useRef } from "react";
// import FiberCanvas from "./FiberCanvas";
// import Intro from "./lottieAnim/Intro";
import expData from "../assets/expData.json";
import Lottie from "lottie-react";
import animationData from "../lotties/zatgeist-intro.json";
import scroll from "../lotties/scroll.json";
import hipat from "../lotties/hipat.json";
import closeBtn from "../lotties/close-btn.json";
import { useInView } from "react-intersection-observer";
import expAnim from "../lotties/exp.json";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import industrial from "./3d-fiber/industrial_pipe_and_valve_01_4k.hdr";
import { Canvas } from "@react-three/fiber";
import { animated, useSpring, config } from "@react-spring/three";
import { Environment, useGLTF } from "@react-three/drei";
// import { enumNumberBody } from "@babel/types";
import { flatten, replaceColor } from "lottie-colorify";
import SvgBarcode from "../assets/bc.js";
import FireLottie from "./FireLottie";
import WarningIconSvg from "./WarningIconSvg";

const ParallaxContainer = () => {
  let hipatA = replaceColor("#ffffff", "#000000", hipat);
  hipatA = replaceColor("#231F20", "#f0f0f0", hipatA);
  hipatA = replaceColor("#B7B7B7", "#000000", hipatA);
  let scrollA = replaceColor("#231F20", "#f0f0f0", scroll);
  scrollA = replaceColor("#ffffff", "#000000", scrollA);
  scrollA = replaceColor("#E6E6E6", "#000000", scrollA);

  let expAnimA = flatten("#000000", expAnim);

  const lottieRef = useRef();
  const scrollRef = useRef();
  const hiRef = useRef();
  const expRef = useRef();
  // const cursorAnim = useRef();
  // const [hiRef, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.8,
  // });

  const main = useRef();
  // const can = useRef();
  // const x = useRef(0);
  // const dot = useRef();
  // const containerRef = useRef(null);
  const parallax = useRef();
  const containerRef = useRef();
  //const [scrollStarted, setScrollStarted] = useState(false);
  // const [scrollPercent, setScrollPercent] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [canActive, setCanActive] = useState(false);
  // const [canSticky, setCanSticky] = useState(false);

  const handleToggle = (index) => {
    if (isExpanded === index) {
      setIsExpanded(null);
    } else {
      setIsExpanded(index);
    }
  };
  // useEffect(() => {
  //   if (inView) {
  //     hiRef.play();
  //   }
  // }, [inView]);

  // const { width, height } = useThree((state) => state.viewport)

  const [springs, api] = useSpring(
    () => ({
      scale: 1,
      // position: [0, -3, 0],
      rotation: [0, 6.28, 0],

      config: (key) => {
        switch (key) {
          case "scale":
            return {
              mass: 1,
              friction: 10,
              precision: 0.0001,
            };
          // case "rotation":
          //   return {  friction: 100,  precision: 0.0001, };
          default:
            return {};
        }
      },
    }),
    []
  );

  const alignCenter = { display: "flex", alignItems: "center" };

  // const toggleCursorVisibility = () => {
  //   if (cursorVisible.current) {

  //   }
  // }

  // const delay = 18;
  // const cursorVisible = useRef(true);
  // const cursorEnlarged = useRef(false);

  // const endX = useRef(window.innerWidth / 2);
  // const endY = useRef(window.innerHeight / 2);
  // const _x = useRef(0);
  // const _y = useRef(0);

  // const mouseMoveEvent = (e) => {
  //   cursorVisible.current = true;
  //   //toggleCursorVisibility();
  //   endX.current = e.pageX;
  //   endY.current = e.pageY;
  //   enumNumberBody.current = e.pageY;
  //   dot.current.style.top = endY.current + "px";
  //   dot.current.style.left = endX.current + "px";
  // };

  // useEffect(() => {
  //   window.addEventListener("mousemove", mouseMoveEvent);
  //   return () => {
  //     window.removeEventListener("mousemove", mouseMoveEvent);
  //   };
  // }, []);

  // const onScroll = () => {
  //   console.log(parallax.current.current / parallax.current.space)
  //   // if (!parallax.current || !parallax.current.container) return
  //   // const { container, current } = parallax.current;
  //   //     const scrollpercent =
  //   //       current / (container.current.scrollHeight - window.innerHeight);
  //   //       console.log(scrollpercent)
  // }

  // useEffect(() => {
  //   if (!parallax.current || !parallax.current.container) return
  //   parallax.current.container.onscroll = onScroll();
  // },[])

  function scrollListener() {
    const container = parallax.current?.container.current;

    container.onscroll = () => {
      // console.log(parallax.current?.current / parallax.current?.space)
      const scrollpercent =
        parallax.current?.current /
        (container.scrollHeight - window.innerHeight);
      console.log(scrollpercent);
      // setScrollPercent(scrollpercent);
      if (scrollpercent > 0) {
        api.start({
          rotation: [scrollpercent * 16, 0, 0],
        });
        hiRef.current.play();
      }
      if (scrollpercent > 0.4) {
        api.start({
          rotation: [6.28, 0, 0],
        });
      }
      if (scrollpercent > 0.5) {
        api.start({
          rotation: [6.28, scrollpercent * 20, 0],
        });
      }
    };
    return () => {
      container.onscroll = null;
    };
  }

  useEffect(scrollListener, []);

  const { nodes, materials } = useGLTF("/canImageTxture1.glb");
  return (
    <div ref={containerRef} className="scrollCon">
      {/* <div className="scrollPercent">{scrollPercent}</div> */}
      <div className="lateral">
        <div className="limited">
          <div>Zatgeist.com Welcome to my 2023 portfolio site</div>
        </div>
      </div>
      {/* <div ref={dot} className="cursor-dot">
        <Lottie
          animationData={closeBtn}
          //speed={1.5}
          lottieRef={cursorAnim}
          loop={false}
          autoplay={false}
          height={400}
          width={400}
        />
      </div> */}
      <Parallax ref={parallax} pages={9}>
        <ParallaxLayer
          className="parallaxLayer"
          offset={0}
          speed={1}
          factor={1}
        >
          <div className="lottie-intro">
            <div className="lottie-logo">
              <Lottie
                animationData={animationData}
                lottieRef={lottieRef}
                autoplay={true}
                loop={false}
                height={400}
                width={400}
                onComplete={() =>
                  scrollRef.current.playSegments(
                    [
                      [0, 240],
                      [241, 290],
                    ],
                    true
                  )
                }
              />
            </div>
            <div className="lottie-scroll-con">
              <div className="lottie-scroll">
                <Lottie
                  animationData={scrollA}
                  lottieRef={scrollRef}
                  loop={true}
                  autoplay={false}
                  height={100}
                  width={100}
                />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 1, end: 1.5 }}
          speed={1}
          factor={1}
          style={{ ...alignCenter, justifyContent: "center", zIndex: "120" }}
          className="parallaxLayer"
        >
          <div className="card parallax layer3">
            <Lottie
              animationData={hipatA}
              lottieRef={hiRef}
              loop={false}
              autoplay={false}
              height={100}
              width={100}
            />
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 1.5, end: 5.5 }}
          className="parallaxLayer layer1"
        >
          <div className="sticky-con">
            <div className={`scrollCon`}>
              <Canvas
                shadows
                camera={{
                  position: [8, 2, 9],
                  fov: 100,
                  inputColorSpace: "srgb",
                  workingColorSpace: "srgb-linear",
                  outputColorSpace: "srgb",
                  toneMapping: "filmic",
                }}
              >
                <Environment files={industrial} blur={0.2} />
                <ambientLight intensity={0.2} />
                <directionalLight
                  color="red"
                  position={[-2, 0, -15]}
                  castShadow
                />
                <animated.group
                  dispose={null}
                  ref={main}
                  rotation={springs.rotation}
                >
                  <mesh
                    geometry={nodes.bolt_mesh.geometry}
                    material={materials.can}
                    position={[0, 5.238, 0]}
                  />
                  <group position={[0, 0.585, 0.074]}>
                    <mesh
                      geometry={nodes.can_mesh_1.geometry}
                      material={materials.can}
                      resolution={2048}
                    />
                    <mesh
                      geometry={nodes.can_mesh_2.geometry}
                      material={materials.image}
                      resolution={2048}
                    />
                    <mesh
                      geometry={nodes.can_mesh_3.geometry}
                      material={materials["Material.001"]}
                    />
                  </group>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.tap_mesh.geometry}
                    material={materials.can}
                    position={[0, 5.229, -0.107]}
                  />
                </animated.group>
              </Canvas>
            </div>
          </div>
        </ParallaxLayer>

        {/* <ParallaxLayer
          sticky={{ start: 2.5, end: 2.66 }}
          speed={0.5}
          className="parallaxLayer layer3"
        >
          <div className="bigTxt">
            <p>Frontend Engineer</p>
          </div>
        </ParallaxLayer> */}
        <ParallaxLayer
          sticky={{ start: 2.5, end: 2.66 }}
          speed={0.5}
          className="parallaxLayer layer3"
        >
          {/* <div className="lateral">
            <div className="limited"> */}
          <div className="paraLineCon">
            <div className="bigPara1 uppercase">
              <span>Senior</span>
              <span>Frontend</span>
              <span className="innerPar">
                directing the power of digital branding
              </span>
              <span>Engineer</span>
              <span>+</span>
              <span>Designer</span>
            </div>
            <div className="bigPara1 uppercase">
              <span>Built To</span>
              <span className="innerPar">High-impact engagement for all</span>
              <span> disrupt the</span>
              <span className="warningSvg">
                <div className="fire">
                  <FireLottie />
                </div>

                <WarningIconSvg />
              </span>
              <span>Digital Experience</span>
            </div>
          </div>
          {/* </div>
          </div> */}
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 4, end: 5 }}
          style={{ ...alignCenter, justifyContent: "center" }}
          className="parallaxLayer layer3"
        >
          <div className="cardCon parallax purple">
            <div className="cardTop">
              <h1>No.1</h1>
              <p>purchased</p>
            </div>
            <p className="rotate-rl">this is my resume</p>
            <div className="cardBottom">
              <div className="smallPs">
                <p>
                  Lead and Senior Developer with a focus on React, Typescript,
                  React-Redux, and Next.js applications. Strong background in
                  design and animation. Architecture, wireframing, e-commerce,
                  networking and deployment included.
                </p>
                <p>
                  This site was built and designed by myself, P@ Z@. Deployed on
                  digital ocean with CI/CD pipeline. 3D object model designed in
                  adobe substance. Using react-three-fiber, react-spring, and
                  react-lottie for animation.
                </p>
              </div>
              <SvgBarcode className="barcode"></SvgBarcode>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={5} speed={1} className="layer1 parallaxLayer">
          <div className="bigTxt">
            <p>A DECADE OF CODING EXCELLENCE</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={4} speed={1.5}>
          <div className="expAnim">
            <Lottie
              animationData={expAnimA}
              lottieRef={expRef}
              loop={true}
              autoplay={false}
              height={1500}
              width={1500}
            />
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={6.75} speed={1} className="parallaxLayer">
          <div className="exp">
            {expData.map((job, index) => (
              <div key={index}>
                <div
                  className="section-col"
                  // handleToggle={handleToggle}
                  onClick={() => handleToggle(index)}
                >
                  <div className="section-row section-head">
                    <h1 className="number">No.{index}</h1>
                    <div className="title">
                      {job.title}
                      <span>
                        <div>{isExpanded === index ? "x" : "+"}</div>
                      </span>
                    </div>
                  </div>
                  {isExpanded === index && (
                    <div className="section-row collapse">
                      <div className="jobInfo">
                        {/* <div className="stack">
                          <img src="https://www.cdnlogo.com/logos/m/57/material-ui.svg" />
                        </div> */}
                        <div className="flexrow resp">
                          <h3>Responsibilities:</h3>
                          <p>{job.description}</p>
                        </div>

                        <p>
                          <h2 className="infoHeader">Projects</h2>
                          {job.project?.map((proj, index) => (
                            <div className="proj-con flexcol">
                              <div className="proj flexrow">
                                <span>{proj.name}:</span>
                                <p>{proj.description}</p>
                              </div>
                            </div>
                          ))}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  );
};
useGLTF.preload("/canImageTxture.glb");

export default ParallaxContainer;
